import React from "react";
import Section from "../../../containers/Section";
import Header, { HeaderVariant } from "../../Header";
import { StaticImage } from "gatsby-plugin-image";
import RowImageContent from "../../RowImageContent";

interface CalculatorFeaturesProps {
  title: string[];
  description: string[];
  features?: {
    img: typeof StaticImage;
    title: string;
    description: string;
    imgOrder?: string;
    contentClassName?: string;
  }[];
}

const CalculatorFeatures: React.FC<CalculatorFeaturesProps> = props => {
  const { title, description, features } = props;

  return (
    <Section className="mt-18">
      <div className="w-full">
        <Header variant={HeaderVariant.h2}>
          {title.map((title: string, index: number) => {
            return (
              <span
                key={index}
                className="block text-center md:text-left text-[29px] sm:text-[50px] lg:text-5xl"
              >
                {title}
              </span>
            );
          })}
        </Header>
        <p className="md:text-4xl font-semibold text-center md:text-left">
          {description && description}
        </p>
      </div>
      <div className="mt-24">
        {features &&
          features.map((feature: any, index: number) => {
            return (
              <RowImageContent
                key={index}
                title={feature?.title}
                headerVariant={HeaderVariant.h3}
                description={feature?.description}
                bulletPoints={true}
                image={feature?.img}
                imgOrder={feature.imgOrder}
                contentClassName={feature.contentClassName}
              />
            );
          })}
      </div>
    </Section>
  );
};

export default CalculatorFeatures;
