import React from "react";
import Section from "../../../containers/Section";
import Header, { HeaderVariant } from "../../Header";
import { IconType } from "../../Icon";
import IconTitleDescription from "../../IconTitleDescription";

interface FactorsProps {
  title: string;
  features?: {
    icon: IconType;
    title: string;
    description: string;
  }[];
}

const Factors: React.FC<FactorsProps> = props => {
  const { title, features } = props;

  return (
    <Section className="mt-18 lg:mb-32">
      <div className="w-full md:max-w-6xl">
        <Header variant={HeaderVariant.h2}>{title}</Header>
      </div>
      <div className="grid grid-cols-1 justify-center items-center sm:grid-cols-2 lg:grid-cols-3 mt-18 text-center md:text-left">
        {features &&
          features.map((feature: any, index: number) => {
            return (
              <IconTitleDescription
                key={index}
                iconBg={"bg-green-3-light"}
                iconFill={"text-green-2-dark"}
                iconType={feature?.icon}
                title={feature?.title}
                description={feature?.description}
              />
            );
          })}
      </div>
    </Section>
  );
};

export default Factors;
