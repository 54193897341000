import React from "react";
import Section from "../../../containers/Section";
import Header, { HeaderVariant } from "../../Header";
import { StaticImage } from "gatsby-plugin-image";
import RowImageContent from "../../RowImageContent";

interface PerpetuaProfitProps {
  title: string[];
  features?: {
    img: typeof StaticImage;
    title: string;
    description: string;
    imgOrder?: string;
    contentClassName?: string;
  }[];
}

const PerpetuaProfit: React.FC<PerpetuaProfitProps> = props => {
  const { title, features } = props;

  return (
    <Section className="mt-18">
      <div className="w-full lg:max-w-6xl mx-auto text-center">
        <Header variant={HeaderVariant.h2}>
          <span className="sm:text-[48px] lg:text-5xl">{title}</span>
        </Header>
      </div>
      <div className="mt-24">
        {features &&
          features.map((feature: any, index: number) => {
            return (
              <RowImageContent
                key={index}
                headerVariant={HeaderVariant.h3}
                title={feature?.title}
                description={[feature?.description]}
                image={feature?.img}
                imgOrder={feature.imgOrder}
                contentClassName={feature.contentClassName}
              />
            );
          })}
      </div>
    </Section>
  );
};

export default PerpetuaProfit;
