import { graphql } from "gatsby";
import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import SEO from "../components/Seo";
import "../components/VideoAdsPage/video-ads-page.scss";
import Layout from "../containers/Layout";
import HeroSection from "../components/HeroSection";
import { IconType } from "../components/Icon";
import BottomCTA from "../components/BottomCta";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { Name, Type, Location, Category } from "../utils/analytics/constants";
import { useSegment } from "../utils/analytics";
import { nav } from "../utils/navigation";
import Section from "../containers/Section";
import Header, { HeaderVariant } from "../components/Header";
import CalculatorFeatures from "../components/AmazonProfitCalculatorPage/CalculatorFeatures";
import Factors from "../components/AmazonProfitCalculatorPage/Factors";
import PerpetuaProfit from "../components/AmazonProfitCalculatorPage/PerpetuaProfit";
import PowerfulKPI from "../components/AmazonProfitCalculatorPage/PowerfulKPI";
import FullWidthQuote from "../components/FullWidthQuote";
import Paragraph from "../components/Paragraph";

interface AmazonProfiCalculatorProps {
  data: any;
}

const AmazonProfiCalculator: React.FC<AmazonProfiCalculatorProps> = ({
  data
}) => {
  const pageData = data;
  const { t } = useTranslation();
  const { helpers } = useSegment();

  const heroCta = {
    text: t("Get Started"),
    route: t("/demo")
  };

  const calculatorFeatures = [
    {
      img: (
        <StaticImage
          src="../images/amazon-profit-calculator/advertising-integration.png"
          alt="Search Insights"
          className="w-full mt-10 lg:mt-0"
        />
      ),
      title: t("Full advertising integration"),
      description: [
        t(
          "Within the Seller Edition, your Organic and PPC activities are fully integrated within the Perpetua Profit Module, so you can see the full picture of your Amazon business"
        ),
        t(
          "Perpetua Profit goes beyond ACOS – see your true seller profit for all of your products, from individual ASIN through full product portfolio"
        ),
        t(
          "Vendor customers can use Perpetua Profit to see their Profit breakdown if they also sell through Amazon’s Seller Central (Hybrids)"
        )
      ],
      imgOrder: "order-2",
      contentClassName: "lg:pr-18"
    },
    {
      img: (
        <StaticImage
          src="../images/amazon-profit-calculator/true-profit.png"
          alt="Share of Voice"
          className="w-full mt-10 lg:mt-0"
        />
      ),
      title: t("Calculate and analyze your “true profit”"),
      description: [
        t(
          "Perpetua Profit incorporates all of the key inputs and fees including Amazon fees, FBA fees, refunds, historical CoG, promotional costs and many more"
        ),
        t(
          "See your profitability at any level you like – from marketplace down to individual child-ASIN"
        ),
        t(
          "It’s fully configurabile – adjust the charts to view any KPIs you like"
        )
      ],
      imgOrder: "order-2 lg:order-1",
      contentClassName: "lg:pl-18"
    },
    {
      img: (
        <StaticImage
          src="../images/amazon-profit-calculator/business.png"
          alt="Organic Rank Tracking"
          className="w-full mt-10 lg:mt-0"
        />
      ),
      title: t("Your business in your pocket"),
      description: [
        t(
          "Perpetua Profit also comes with the exclusive Perpetua Profit Mobile App for your phone"
        ),
        t(
          "Track every sale in real-time, the data is refreshed every few minutes"
        ),
        t(
          "See sales and profit across parent and child ASINs, your full product portfolio, and across all marketplaces you sell on"
        )
      ],
      imgOrder: "order-2",
      contentClassName: "lg:pr-18"
    }
  ];

  const factorsList = [
    {
      icon: IconType.funnel,
      title: t("Breakdown of sales vs costs"),
      description: t(
        "Perpetua Profit is seamlessly integrated with Seller Central and your profit data is displayed in real-time. See the split between your organic sales and sales generated through Sponsored Products (‘PPC’) ads. Know exactly what is driving your costs and how those costs affect your seller profit on Amazon."
      )
    },
    {
      icon: IconType.pie,
      title: t("Your costs’ effects on your True Profit"),
      description: t(
        "Our Profit shows your exact profit margins after all your costs are deducted. Detailed cost inputs including Amazon fees, PPC revenue and spend, promos, item costs, coupons, returns, etc. are included, giving you the most accurate picture of your costs’ impact on your true profit."
      )
    },
    {
      icon: IconType.pie,
      title: t("Profit calculation by SKUs"),
      description: t(
        "Your Profit Dashboard instantly visualizes the profitability of each SKU. We break down each cost metric, allowing you to calculate which area is disproportionately eating into your Amazon profit margins, enabling you to make critical decisions immediately."
      )
    }
  ];

  const quote = {
    text: t(
      "A Seller’s revenue can be cut into by more than 70 different kinds of fees!"
    ),
    author: {
      name: "Perpetua Product Manager",
      img: (
        <StaticImage
          src="../images/amazon-profit-calculator/perpetua-manager.png"
          alt="quote"
          width={64}
          height={64}
        />
      )
    }
  };

  const profitFeatures = [
    {
      img: (
        <StaticImage
          src="../images/amazon-profit-calculator/profit-ui-chart.png"
          alt="Search Insights"
          className="order-2 mx-auto lg:mx-0 mt-10"
        />
      ),
      title: t("See your profit and PPC data combined"),
      description: t(
        "Perpetua Profit is the only profit offering on the market to combine both your revenue from organic (SEO) activities, PPC spend and revenue, as well as other business costs, giving you the truest, and most accurate picture of your overall profitability."
      ),
      imgOrder: "order-2",
      contentClassName: "lg:pr-10"
    },
    {
      img: (
        <StaticImage
          src="../images/amazon-profit-calculator/profit-ui-flags.png"
          alt="Share of Voice"
          className="mx-auto lg:mx-0 mt-10"
        />
      ),
      title: t("See data for multiple markets and marketplaces"),
      description: t(
        "Check your performance across an entire marketplace to see how well your business is doing in the region. Perpetua Profit works for all marketplaces you sell in."
      ),
      imgOrder: "order-2 lg:order-1 ",
      contentClassName: "lg:pl-10"
    },
    {
      img: (
        <StaticImage
          src="../images/amazon-profit-calculator/profit-ui-merge.png"
          alt="Organic Rank Tracking"
          className="order-2 mx-auto lg:mx-0 mt-10"
        />
      ),
      title: t("Integrate multiple seller central accounts"),
      description: t(
        "If you currently have multiple Seller Central Accounts, you can see an integrated, holistic view of your sales, costs and profit breakdowns."
      ),
      imgOrder: "order-2",
      contentClassName: "lg:pr-10"
    },
    {
      img: (
        <StaticImage
          src="../images/amazon-profit-calculator/profit-ui-cogs.png"
          alt="Organic Rank Tracking"
          className="mx-auto lg:mx-0 mt-10"
        />
      ),
      title: t("Add and modify your COGS & VAT as often as you wish"),
      description: t(
        "Times are changing and so are your costs. This allows you to have the truest picture of what is happening as shifts happen in your business."
      ),
      imgOrder: "order-2 lg:order-1",
      contentClassName: "lg:pl-10"
    },
    {
      img: (
        <StaticImage
          src="../images/amazon-profit-calculator/profit-ui-export-asins.png"
          alt="Organic Rank Tracking"
          className="order-2 mx-auto lg:mx-0 mt-10"
        />
      ),
      title: t("Export data using custom or bulk exports"),
      description: t(
        "Easily export your profit dashboard data so you can analyze in any software you choose. Exports are available using both customized reports, or export all of your data using a bulk export."
      ),
      imgOrder: "order-2",
      contentClassName: "lg:pr-10"
    }
  ];

  const KPI_Features = [
    {
      text: "Units",
      amount: "1486",
      percentage: "-4.7%"
    },
    {
      text: "Orders",
      amount: "1330",
      percentage: "-7"
    },
    {
      text: "Net Sales",
      amount: "$46,118.50",
      percentage: "+1.1%"
    },
    {
      text: "Operation Profit",
      amount: "$6,044.62",
      percentage: "+234.6%"
    },
    {
      text: "Profit Margin",
      amount: "15.35%",
      percentage: "+231.6%"
    },
    {
      text: "ROI",
      amount: "0.58",
      percentage: "+244.9%"
    }
  ];

  return (
    <Layout>
      <SEO
        title={t("Amazon Seller Profit Calculator")}
        description={t("Perpetua Profit is the profit solution to seamlessly integrate both your Advertising & SEO activities, along with granular costs inputs to show your True Profit")}
        image={`https://perpetua.io${pageData?.openGraphImage?.publicURL}`}
      />

      <HeroSection
        bgGradient="bg-gradient-to-b from-green-3 to-white"
        page={""}
        gradientTitle={[
          t("Track your"),
          t("true profit"),
          t("on Amazon in real time")
        ]}
        color="green"
        gradientPosition={2}
        titleClassName="!max-w-2xl pr-8"
        h1Title={t("Perpetua Profit")}
        icon={IconType.arrowUp}
        iconColor="text-green-2-dark"
        description={t(
          "Integrate your advertising & organic activities, add your specific costs and track your true profit in real-time."
        )}
        cta={heroCta}
        imageReplacement={
          <StaticImage
            src="../images/amazon-profit-calculator/hero-img.png"
            alt="Amazon Profit Calculator and Tracker"
            className="w-full hidden md:block"
          />
        }
      />
      <Section>
        <div className="text-center pb-20 md:pt-28 md:pb-24">
          <div className="m-auto w-full lg:max-w-7xl">
            <Header variant={HeaderVariant.h2}>
              {t(
                "Understanding your Amazon seller profit doesn’t have to be time consuming or complex"
              )}
            </Header>
            <Paragraph className="md:px-8 lg:max-w-5xl mx-auto">
              {t(
                "In the world of Amazon businesses, profit tracking is not an easy task, especially when you have to search through individual ASINs, across an entire product family, or even a marketplace. It includes a complex set of inputs covering unit sales, revenue, CoGs, VAT, taxes, fees & refunds. Perpetua Profit helps you to calculate your true profit from your Amazon business."
              )}
            </Paragraph>
          </div>
        </div>
      </Section>

      <FullWidthQuote
        bgColor="bg-gradient-to-b from-green-3 to-green-2-light"
        textColor="text-green-3-dark"
        quote={quote}
      />

      <CalculatorFeatures
        title={[
          t("More than a simple Amazon profit calculator."),
          t("More advanced than your typical profit dashboard. ")
        ]}
        subtitle={t("Listing Optimization")}
        description={t(
          "Perpetua simplifies your view of your Profit on Amazon in an easy-to-use module that’s available within the All‑in‑One Perpetua software editions."
        )}
        features={calculatorFeatures}
      />

      <PowerfulKPI
        title={t("Numbers transferred into powerful KPIs")}
        description={[
          t(
            "Seamless integration across costs, fees, taxes and business activities – like units sold and total revenue"
          ),
          t(
            "Robust and dynamic cost inputs – we know fees and order costs can change over time!"
          ),
          t(
            "Easily exportable data, easy to understand dashboards, and on-the-go, live-time access to your full Amazon business information"
          )
        ]}
        features={KPI_Features}
      />

      <Factors
        title={t(
          "Understand which factors are truly driving your profit and manage your business the right way"
        )}
        features={factorsList}
      />
      <PerpetuaProfit
        title={t(
          "How is Perpetua Profit different from other profit calculators and dashboards?"
        )}
        description={t(
          "Perpetua simplifies your view of your Profit on Amazon in an easy-to-use module that’s available within the All‑in‑One Perpetua software editions."
        )}
        features={profitFeatures}
      />

      <BottomCTA
        title={t("Ready to try Perpetua Profit?")}
        primaryButtonText={t("Get Started")}
        primaryButtonOnClick={() =>
          helpers
            .delayTrack(Name.Cta, {
              category: Category.ButtonClick,
              type: Type.Button,
              text: "Get Started",
              page_url: window?.location.href,
              destination: t("/demo/"),
              location: Location.BottomCta
            })
            .then(() => {
              nav(t("/demo/"));
            })
        }
      />
    </Layout>
  );
};

export default AmazonProfiCalculator;

export const data = graphql`
  query {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
    openGraphImage: file(relativePath: { eq: "amazon-profit-calculator/ogImage.png" }) {
      publicURL
    }
  }
`;
