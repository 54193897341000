import React from "react";
import Section from "../../../containers/Section";
import Header, { HeaderVariant } from "../../Header";
import { StaticImage } from "gatsby-plugin-image";

interface PowerfulKPIProps {
  title?: string;
  description?: string[];
  features?: {
    text: string;
    amount: string;
    percentage: string;
  }[];
}

const PowerfulKPI: React.FC<PowerfulKPIProps> = props => {
  const { title, description, features } = props;

  return (
    <div className="bg-green-3-light rounded-tl-large rounded-br-large">
      <Section className="pt-20 pb-20">
        <div className="flex flex-col lg:flex-row items-center justify-between">
          <div className="w-full lg:w-1/2">
            <Header
              className="md:text-5xl md:font-bold mb-8 lg:max-w-xl text-center md:text-left"
              variant={HeaderVariant.h3}
            >
              {title}
            </Header>
            <div className="lg:max-w-xl">
              {description?.map((item, index) => {
                return (
                  <p key={index} className="flex mb-3 items-center">
                    <span className="inline-block p-1 bg-green-1 rounded-full border-4 border-green-2-light"></span>
                    <span className="ml-5 text-xl">{item}</span>
                  </p>
                );
              })}
            </div>
          </div>
          <div className="w-full lg:w-1/2 mt-12 lg:mt-0 ">
            <div>
              <StaticImage
                src="../../../images/amazon-profit-calculator/true-profit-chart.png"
                alt="Seo Sonar"
                className="w-full"
              />
            </div>
          </div>
        </div>
      </Section>
    </div>
  );
};

export default PowerfulKPI;
